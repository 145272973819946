*{
  box-sizing: border-box;
  scroll-behavior: smooth;
}
h1, h2,h3, h4, h5,h6,p{
  text-shadow: 1px 1px 2px rgb(0, 0, 0,0.1);
}
p{
  margin: 5px;
  line-height: 1.5;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white; /* warm blue */
}
.daisy{
  border-radius: 50%;
  margin: 0.5rem;
  box-shadow: 0 5px 5px rgba(37,39,44,.05);
  width: 80%;
  max-width: 500px;
}
.footer h4{
  text-align: center;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.consultants{
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  align-items: center;
  padding: 2rem;
}
.consultants p{
  color: #555;
  line-height: 1.6;
  font-size: 18px;
}
.consultant-item {
  position: relative;
  display: inline-block;
  padding-bottom: 2rem;
}
.consultant-item h3{
  font-size: 24px;
}
.consultant-img{
  height: auto;
  align-self: center;
  max-width: 100%;
  border-radius: 50%; /* corners rounded */
  object-fit: cover;
  width: 150px; /* reduced size to small */
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
}
.testimonial-img{
  height: auto;
  align-self: center;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
  width: 190px; /* reduced size to small */
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
}
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.navbar {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #f7f7f7;
  width: 100%;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #270e0c;
  text-align: center;
  justify-content: space-between;
  z-index: 3;
  min-height: 10vh;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(37,39,44,.05),0 7.5px 10px rgba(37,39,44,.2);

  /* Add a transition effect for hover */
  transition: all .3s ease-in-out;
}
.navbar img{
  width: 35vw;
}


.navbar:focus {
  outline: none; /* Remove the focus outline */
}

/* Modify the .navbar p tag rule */
.navbar p {
  cursor: pointer; /* Add a cursor pointer to indicate it's clickable */
  font-size: 15px;
  font-weight: 600;
  /* Change text color on hover */
  transition: All .3s ease-in-out;
  background-color: #270e0c;
  color: white;
  padding: 0.4rem;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
}

/* Modify the :hover rule for the p tag */
.navbar p:hover {
  color: #cacaca; /* Change this to any other color you want when hovering */
}

.legal{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  font-size: 3vw;
  text-underline-offset: 5px;
}
.legal a:hover{
  color:#888787
}
/* Remove bullet points from nav and space it out evenly */
.navbar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#services{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #fffcfa;
  padding: 2rem 2rem 2rem 2rem;
}
.services-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Remove default text color from links */
A {
  color: inherit;
}

.col-lg-6 {
   /* Adjust the color and thickness as needed */
  box-sizing: border-box; /* Include padding and borders in element width and height */
  margin-bottom: 2rem; /* Add margin between columns */
}
.col-lg-6 h2{
  margin-left: 1rem;
  text-align: center;
  margin: 0.8em;
  font-size: 20px;
  text-underline-offset: 5px;
}
/* Optionally, if you want to style the box around the content within these columns, adjust this */
.col-lg-6 .service-item {
  background-color: #ffffff; /* White box background */
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
}

/* You can also add hover effects to make the box stand out more */
.col-lg-6 .service-item:hover {
  cursor: pointer;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.service-item h3{
  margin-left: 5px;
}
.section-header {
  margin: 2rem 0;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.testimonials-container h4{
  margin: 4px;
}
.testimonial-box {
  background-color: #ffffff; /* White box background */
  border: 1px solid #ddd; /* Gray border */
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
  gap: 25px;
}

.testimonial-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-text {
  font-size: 20px;
  color: #555;
  line-height: 1.6;
  margin-top: 10px; /* Add some space above the text */
  text-align: center; /* Center the text horizontally */
}

.client-name {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  margin-bottom: 5px; /* Add some space below the name */
}
@media screen and (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    align-items: flex-start; /* Align items to the left on small screens */
  }

  .col-lg-6 {
    margin-bottom: 1rem;
  }

  /* Adjust the font size for larger screens, and make sure the container has a max width */
  .App-header h1 {
    font-size: 24px;
  }

  .testimonials-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column width for smaller screens */
  }

  /* Make the images larger on mobile devices */
  .consultant-img {
    width: 100%; /* Let the image take up its full container width */
  }
  .contact-us{
    padding: 1rem;
  }

  form {
    width: 100%;
  }
}

.contact-us{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.contact-us p{
  margin: 0;
}
form {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

form > b{
  padding-top: 10px;
}
form label {
  display: block;
  font-weight: bold;
  margin-top: 1.5rem;
  line-height: 2em; /* Adjusted line height for uniformity */
}

form input, form textarea {
  width: 100%; /* Set the width of all form elements to 100% */
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

form textarea {
  min-height: 100px; /* Set the minimum height for the textarea */
  resize: none;
  line-height: 2em; /* Matching line height to the labels */
}

form select {
  width: 100%; /* Set the width of the select element to 100% */
  padding: .8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #ffff;
}

form select option {
  padding: .6rem 1rem; /* Add some padding to each option for better readability */
  border-radius: 3px;
  background-color: #f9f9f9; /* Light gray background color for options */
}

form select option:hover,
form select option:focus {
  background-color: #e1e1e1; /* Slightly darker background on hover or focus */
}

form button {
  width: 80%; /* Set the width of the button to 100% */
  min-width: 300px;
  padding: .8rem;
  border: none;
  background-color: #270e0c; /* Blue color */
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1.5rem;
  box-shadow: 0 5px 10px rgba(37,39,44,.05),0 15px 40px rgba(37,39,44,.2);
  align-self: center;
}
.availability-calendar{
  display: flex;
  flex-direction: column;
}
.availability-calendar select{
  min-width: 300px;
}
.calendar-popup{
  display: flex;
  gap: 15px;
  width: 100%;
}

.calendar-popup input,
.calendar-popup select{
  width: 100%;
}

form b,
form p{
  line-height: 1.5;
}
/* Base styles */
.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fffcfa;
}

form label, form input, form textarea, form select, form button {
  width: 100%;
  max-width: 1000px; /* Adjust for larger screens */
}

/* Responsive styles for smaller screens (mobile) */
@media screen and (max-width: 768px) {
  .contact-us p, form label, form input, form textarea, form select, form button {
    font-size: 16px; /* Adjust font size for smaller screens */
    margin-top: .8rem;
  }

  .availability-calendar select {
    min-width: 200px; /* Adjust select width for mobile */
  }

  .calendar-popup {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    gap: 10px;
  }
}

@media screen and (max-height: 600px) {
  /* If the viewport is too short, reduce padding to make more space for scrolling */
  .contact-us {
    padding: 1rem;
  }
}
#date-picker{
  color: blue;
}
.footer{
  padding: 0 2rem 2rem 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  color: #555;
}
.footer p{
  text-align: center;
}
.intro{
  min-height: 100vh;
  display: flex;
}
.message-contact-info input,
.message-contact-info textarea{
 padding: 1.5vw;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 2em 2em 2em;
  min-height: 100vh;
  background-color: #fffcfa;
}
.consulting .hero{
  min-height: 90vh;
}
.hero p{
  color: #555;
  font-size: 19px;
  line-height: 1.6;
}

.hero__image-container {
  width: 100%;
  max-width: 600px; /* Adjust to your preference */
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hero__image-container h1{
  margin: 5px;
  font-size: 19px;
  text-align: center;
}
.hero__image-container img {
  width: 70vw;
  max-width: 500px;
}

.hero__content {
  text-align: center;
}

.hero-ghg-logo{
  padding: 1rem;
}

.home-services-list{
  margin: 2rem;
}
.home-services-list h2{
  text-align: center;
}
.home-services-list ul{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-services-list li{
  list-style-type: none;
}
.home-services-list li img{
  width: 100%;
}
.consulting .hero .hero__content p{
}